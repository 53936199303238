export const useDefaultUserClaims = () => {
  return {
    isAdmin: false,
    isProductReviewer: false,
    isStudentAccount: false,
    isSystemAdmin: false,
    isDemoAccount: false,
    isIEWInstructor: false,
    availableIEWProductCategoryIds: [],
    availableLevelIds: [],
    availableLessonIds: [],
    classroomPermissions: [],
    studentPermissions: [],
    studentAccountTypes: {},
    isAdvancedClassroomSettingsEnabled: false,
    linkedOrganizationIds: [],
    adminOrganizationIds: [],
  };
};
