import { getAuth, signOut } from "firebase/auth";
import {
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";
import { getActivePinia } from "pinia";
import { Subscription } from "rxjs";
import { AppUser } from "types/AppUser";
import { Article } from "types/Article";
import { CustomClaims } from "types/CustomClaims";
import { GoogleOauthToken } from "~/classes/models/google-oauth/google-oauth-token.model";
import { GoogleOauthService } from "~/classes/models/google-oauth/google-oauth.service";
import { ZoomOauthToken } from "~/classes/models/zoom-oauth/zoom-oauth-token.model";
import { ZoomOauthService } from "~/classes/models/zoom-oauth/zoom-oauth.service";
import { GoogleAPIScope } from "~/types/enums/GoogleAPIScopes.enum";

export const useAppUserData = definePiniaStore("/app/user/data", () => {
  const isInitialized = ref(false);
  const isLoggedIn = ref(false);
  const appUser = ref<AppUser>();

  const customClaims = ref<CustomClaims>({
    ...useDefaultUserClaims(),
  });

  const zoomOauthToken = ref<ZoomOauthToken | undefined>();
  const googleOauthToken = ref<GoogleOauthToken | undefined>();
  const lmsUserConfig = ref<LMSUserConfig>();
  const zoomSubscription = ref<Subscription | null>(null);
  const googleSubscription = ref<Subscription | null>(null);
  const appUserSubscription = ref<Unsubscribe | null>(null);
  const lmsUserConfigSubscription = ref<Unsubscribe | null>(null);

  const { initializeUserQuickComments } = useUserQuickComments();

  const { initializeAnnotations } = useUserAnnotations();

  const logout = async () => {
    // If a store has a reset function implemented, we call it. This ensures the store is being
    //
    const activePinia = getActivePinia();

    ((activePinia as any)?._s as any[]).forEach((store: any) => {
      if (store.reset) {
        store.reset();
      }
    });

    isInitialized.value = false;
    appUser.value = undefined;
    lmsUserConfig.value = undefined;
    zoomOauthToken.value = undefined;
    googleOauthToken.value = undefined;

    useUserQuickComments().reset();
    useUserAnnotations().reset();

    const app = useFirebaseApp();
    const auth = getAuth(app);

    if (!auth) return;

    isLoggedIn.value = false;
    await signOut(auth);
  };

  const initializeAppUserData = async () => {
    if (isInitialized.value == true) {
      return;
    }

    const uid = useCurrentUID();

    if (!uid) {
      return null;
    }

    isLoggedIn.value = true;

    const db = useFirestore();

    try {
      const appUserDocRef = doc(db, `/users/${uid}`);
      const appUserDocSnapshot = await getDoc(appUserDocRef);

      // If the app user doc exists and has the email feild hten we can be confidence that the app user doc was created properly
      // This helps protect from partially created app user docs
      if (appUserDocSnapshot.exists() && appUserDocSnapshot.data().Email) {
        appUser.value = appUserDocSnapshot.data() as AppUser;
      } else {
        const createdDoc = await createAppUser();

        if (createdDoc) {
          appUser.value = createdDoc;
        }
      }

      if (appUserSubscription.value) {
        appUserSubscription.value();
      }

      appUserSubscription.value = onSnapshot(appUserDocRef, (snapshot) => {
        appUser.value = snapshot.data() as AppUser;
      });

      zoomSubscription.value?.unsubscribe();

      zoomSubscription.value = ZoomOauthService.streamRecord(uid).subscribe(
        (token) => {
          zoomOauthToken.value = token;
        }
      );

      googleSubscription.value?.unsubscribe();

      googleSubscription.value = GoogleOauthService.streamRecord(uid).subscribe(
        (token) => {
          googleOauthToken.value = token;
        }
      );
    } catch (error) {
      // empty
    }

    try {
      const lmsUserConfigRef = doc(db, `/lmsUserConfigs/${uid}`);

      if (lmsUserConfigSubscription.value) {
        lmsUserConfigSubscription.value();
      }

      lmsUserConfigSubscription.value = onSnapshot(
        lmsUserConfigRef,
        (snapshot) => {
          isInitialized.value = true;
          lmsUserConfig.value = snapshot.data() as LMSUserConfig;
        }
      );
    } catch (error) {
      isInitialized.value = true;
    }

    try {
      await initializeUserQuickComments();
    } catch (error) {
      // empty
    }

    try {
      await initializeAnnotations();
    } catch (error) {
      // empty
    }
  };

  const loadUserPermissions = async () => {
    const user = useCurrentUser();
    const idTokenResult = await user.value?.getIdTokenResult(true);

    if (idTokenResult && appUser.value) {
      const claims = idTokenResult.claims;

      customClaims.value = {
        isAdmin: claims.isAdmin,
        isProductReviewer: claims.isProductReviewer,
        isStudentAccount: claims.isStudentAccount,
        isSystemAdmin: claims.isSystemAdmin,
        isDemoAccount: claims.isDemoAccount,
        isIEWInstructor: claims.isIEWInstructor ?? false,
        availableIEWProductCategoryIds:
          claims.availableIEWProductCategoryIds ?? [],
        availableLevelIds: claims.availableLevelIds ?? [],
        availableLessonIds: claims.availableLessonIds ?? [],
        classroomPermissions: claims.classroomPermissions ?? [],
        studentPermissions: claims.studentPermissions ?? [],
        studentAccountTypes: claims.studentAccountTypes ?? [],
        isAdvancedClassroomSettingsEnabled:
          claims.isAdvancedClassroomSettingsEnabled ?? false,
        linkedOrganizationIds: claims.linkedOrganizationIds ?? [],
        adminOrganizationIds: claims.adminOrganizationIds ?? [],
      };

      const missingClaims: { [key: string]: any } = {};

      for (const key in useDefaultUserClaims()) {
        if (claims[key] != undefined) continue;

        missingClaims[key] = (useDefaultUserClaims() as any)[key];
      }

      if (Object.keys(missingClaims).length > 0) {
        // Then here wi'll send the missing claims to the server to be updated
        console.log(missingClaims);
        await $fetch(`/api/auth/account/missing-claims`, {
          method: "POST",
          headers: await useApiHeaders(),
        });
      }
    }
  };

  const createAppUser = async () => {
    const user = useCurrentUser();

    const uid = user.value?.uid;

    if (!uid) {
      return null;
    }

    const appUserDoc = {
      id: uid,
      allowNotifications: false,
      allowEmails: false,
      displayName: user.value?.displayName,
      Email: user.value?.email,
      createdOnTimestamp: Date.now(),
      enableBackgroundSync: false,
      showInlineAnnotations: true,
      hasInvertedScrolling: false,
      lastViewedTipDate: useCurrentDateString(),
      hideTutorials: false,
      enableClassrooms: false,
      dashboardLevels: [],
      numGradedDocuments: 0,
    } as AppUser;

    const db = useFirestore();

    const appUserDocRef = doc(db, `/users/${uid}`);
    await setDoc(appUserDocRef, appUserDoc, { merge: true });

    // Save default annotations
    const defaultAnnotations = useUserAnnotations().defaultAnnotations;

    let index = 0;

    for (const annotation of defaultAnnotations) {
      annotation.displayOrder = index;
      annotation.isEnabled = true;
      annotation.isQuickAnnotation = true;
      index++;
    }

    await useUserAnnotations().saveUserAnnotations(defaultAnnotations);

    return appUserDoc;
  };

  const commentColor = computed(() => {
    return appUser.value?.commentColor ?? [255, 0, 0];
  });

  const saveUser = async () => {
    const uid = useCurrentUID();

    if (!uid) {
      return null;
    }

    const db = useFirestore();

    const appUserDocRef = doc(db, `/users/${uid}`);
    await setDoc(appUserDocRef, appUser.value, { merge: true });
  };

  const hasLMSConfig = computed(() => {
    return lmsUserConfig.value != undefined;
  });

  const hasIEWLMS = computed(() => {
    return lmsUserConfig.value?.provider == "iew";
  });

  const hasSakaiLMS = computed(() => {
    return lmsUserConfig.value?.provider == "sakai";
  });

  const hasClassroomMode = computed(() => {
    return appUser?.value?.enableClassrooms == true;
  });

  const isUserAdmin = computed(() => {
    return customClaims.value?.isAdmin || isUserSystemAdmin.value;
  });

  const isUserSystemAdmin = computed(() => {
    return customClaims.value?.isSystemAdmin;
  });

  const isIEWInstructor = computed(() => {
    return customClaims.value?.isIEWInstructor;
  });

  const isUserProductReviewer = computed(() => {
    return isUserAdmin.value || customClaims.value?.isProductReviewer;
  });

  const isDemoAccount = computed((): boolean => {
    return customClaims.value?.isDemoAccount ?? false;
  });

  const availableIEWProductCategoryIds = computed(() => {
    return customClaims.value?.availableIEWProductCategoryIds ?? [];
  });

  const availableLevelIds = computed(() => {
    return customClaims.value?.availableLevelIds ?? [];
  });

  const availableLessonIds = computed(() => {
    return customClaims.value?.availableLessonIds ?? [];
  });

  const classroomPermissions = computed(() => {
    return customClaims.value?.classroomPermissions ?? [];
  });

  const studentPermissions = computed(() => {
    return customClaims.value?.studentPermissions ?? [];
  });

  const studentAccountTypes = computed(() => {
    return customClaims.value?.studentAccountTypes ?? {};
  });

  const studentAccountType = computed(() => {
    return (studentId: string) => {
      return studentAccountTypes.value[studentId];
    };
  });

  const lastViewedTipIndex = computed((): number => {
    return appUser.value?.lastViewedTipIndex ?? 0;
  });

  const lastViewedTipDate = computed((): string | undefined => {
    return appUser.value?.lastViewedTipDate;
  });

  const hideTips = computed((): boolean => {
    return appUser.value?.hideTips ?? false;
  });

  const updateUserLastViewedTip = async (article: Article) => {
    if (!appUser.value) return;
    appUser.value!.lastViewedTipDate = useCurrentDateString();
    appUser.value.lastViewedTipIndex = article.tipDisplayOrder;
    saveUser();
  };

  const completedTours = computed(() => {
    return appUser.value?.completedTours ?? [];
  });

  const markTourAsComplete = async (tourId: string) => {
    if (!appUser.value) return;
    if (completedTours.value.includes(tourId)) return;

    appUser.value.completedTours = [...completedTours.value, tourId];
    await saveUser();
  };

  const isTourComplete = computed(() => {
    return (tourId: string) => {
      return completedTours.value.includes(tourId);
    };
  });

  const hideTutorials = async () => {
    if (!appUser.value) return;
    appUser.value.hideTutorials = true;
    await saveUser();
  };

  const shoudHideTutorial = computed(() => {
    return appUser.value?.hideTutorials ?? false;
  });

  const numGradedDocuments = computed(() => {
    return appUser.value?.numGradedDocuments ?? 0;
  });

  const hasLinkedGoogleAccount = computed(() => {
    return appUser.value?.hasLinkedGoogleAccount ?? false;
  });

  const shouldHideSubmissionNotice = computed(() => {
    return appUser.value?.hideSubmissionNotice ?? false;
  });

  const hideSubmissionNotice = async () => {
    if (!appUser.value) return;
    appUser.value.hideSubmissionNotice = true;
    await saveUser();
  };

  // Zoom
  const hasZoomOauthToken = computed(() => {
    return zoomOauthToken.value != undefined;
  });

  // Google OAtuh
  const hasGoogleOauthToken = computed(() => {
    return googleOauthToken.value != undefined;
  });

  const hasGoogleScope = computed(() => {
    return (scope: GoogleAPIScope) => {
      return googleOauthToken.value?.hasScope(scope) ?? false;
    };
  });
  const isAdvancedClassroomSettingsEnabled = computed(() => {
    return customClaims.value?.isAdvancedClassroomSettingsEnabled ?? false;
  });

  const adminOrganizationIds = computed(() => {
    return customClaims.value?.adminOrganizationIds ?? [];
  });

  const isOrganizationAdministrator = computed(() => {
    return adminOrganizationIds.value.length > 0;
  });

  const isAdministatorForMultipleOrganizations = computed(() => {
    return adminOrganizationIds.value.length > 1;
  });

  const isAdminOfOrganization = computed(() => {
    return (organizationId: string) => {
      return adminOrganizationIds.value.includes(organizationId);
    };
  });

  const isPartOfAnyOrganization = computed(() => {
    return (customClaims.value?.linkedOrganizationIds?.length ?? 0) > 0;
  });

  const isOnlineInstructor = computed(() => {
    return customClaims.value?.isIEWInstructor ?? false;
  });

  const createdOnTimestamp = computed(() => {
    return appUser.value?.createdOnTimestamp;
  });

  return {
    appUser,
    lmsUserConfig,
    commentColor,
    hasLMSConfig,
    hasIEWLMS,
    hasSakaiLMS,
    hasClassroomMode,
    isUserAdmin,
    isUserSystemAdmin,
    isOnlineInstructor,
    isIEWInstructor,
    isUserProductReviewer,
    isDemoAccount,
    isAdvancedClassroomSettingsEnabled,
    availableIEWProductCategoryIds,
    availableLevelIds,
    availableLessonIds,
    classroomPermissions,
    studentPermissions,
    studentAccountTypes,
    studentAccountType,
    lastViewedTipIndex,
    lastViewedTipDate,
    hideTips,
    hasLinkedGoogleAccount,
    adminOrganizationIds,
    isOrganizationAdministrator,
    isAdministatorForMultipleOrganizations,
    isAdminOfOrganization,
    isPartOfAnyOrganization,
    initializeAppUserData,
    loadUserPermissions,
    updateUserLastViewedTip,
    saveUser,
    logout,

    markTourAsComplete,
    isTourComplete,

    hideTutorials,
    shoudHideTutorial,

    numGradedDocuments,

    hideSubmissionNotice,
    shouldHideSubmissionNotice,

    hasZoomOauthToken,

    hasGoogleOauthToken,
    hasGoogleScope,

    isInitialized,
    isLoggedIn,

    createdOnTimestamp,
  };
});
